import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.entity.title))]),_c(VCardText,[_c('SiteTabLinks'),_c(VRow,{staticClass:"pa-4"},[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)],1),_c(VDataTable,{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.category_id`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.category(item.category_id))+" ")]}},{key:`item.price`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.priceFormated(item.price))+" ")]}},{key:`item.weight`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.weightFormated(item.weight))+" ")]}},{key:`item.enabled`,fn:function({ item }){return [_c(VSwitch,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:`item.open`,fn:function({ item }){return [_c('a',{attrs:{"href":`/sites/${_vm.$route.params.id}/products/${item.id}/detail`}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1),_c(VCardActions,{staticClass:"pa-4 d-flex justify-space-between"},[_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),_c(VBtn,{staticClass:"mr-3",attrs:{"color":"light"},on:{"click":_vm.goBack}},[_vm._v("Назад")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }