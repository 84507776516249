<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pa-4">
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="products"
                        class="elevation-1 mt-4"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.category_id`]="{ item }">
                            {{ category(item.category_id) }}
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            {{ priceFormated(item.price) }}
                        </template>
                        <template v-slot:[`item.weight`]="{ item }">
                            {{ weightFormated(item.weight) }}
                        </template>
                        <template v-slot:[`item.enabled`]="{ item }">
                            <v-switch
                                v-model="item.enabled"
                                class="pa-0 ma-0"
                                @change="update(item)"
                                hide-details
                            />
                        </template>
                        <template v-slot:[`item.open`]="{ item }">
                            <a :href="`/sites/${$route.params.id}/products/${item.id}/detail`">
                                <v-icon color="primary">mdi-eye</v-icon>
                            </a>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';

    export default {
        name: 'Products',

        components: {
            SiteTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                weightFormated,
                loading: true,
                headers: [
                    { text: 'Позиция', value: 'position' },
                    { text: 'Категория', value: 'category_id' },
                    { text: 'Активен', value: 'enabled' },
                    { text: 'Название', value: 'title' },
                    { text: 'Цена', value: 'price' },
                    { text: 'Вес', value: 'weight' },
                    { text: '', align: 'right', value: 'open' },
                ],
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            ...mapState('products', {
                products: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            this.loading = true;
            const id = this.$route.params.id;
            await store.dispatch('siteTypes/fetch');
            if(id) {
                await store.dispatch('sites/get', {id});
                await store.dispatch('categories/fetch', {site_id: id});
                await store.dispatch('products/fetch', {site_id: id});
            }
            this.loading = false;
        },

        methods: {
            async create() {
                await this.$router.push({ name: 'sites-products-create', params: { site_id: this.$route.params.id, } });
            },
            async setPicture(file) {
                this.editProduct.picture = await store.dispatch('files/upload', { file });
            },
            async update(product) {
                await store.dispatch('products/update', { site_id: this.$route.params.site_id, product });
            },
            category(id) {
                const category = this.categories.find(item => item.id === id);
                return category ? category.title : '';
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${id}/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>